import { useState } from 'react';
import { useChatCreateMessage } from 'hooks/chat/useChatCreateMessage';
import { AppWrapper, ButtonGroup, CancelBtn, CreateBtn } from './styles';
import { IMessageReference, IResourcesContent } from 'types';
import FilteredContent from '../ChatResourcesFilteredList';

const ResourcesContent = ({
  selectedCategory,
  selectedTags,
  handleCategorySelected,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOpenResoucesBlock = () => {},
}: IResourcesContent) => {
  const [references, setReferences] = useState<IMessageReference[]>([]);
  const createMessage = useChatCreateMessage();

  const handleCreateMessage = async () => {
    await createMessage('', [], references, false, false);
    handleOpenResoucesBlock();
  };

  const handleChangeReferences = (reference: IMessageReference) => {
    setReferences([reference]);
  };

  return (
    <>
      <AppWrapper>
        <FilteredContent
          selectedCategory={selectedCategory}
          selectedTags={selectedTags}
          handleCategorySelected={handleCategorySelected}
          handleChangeReferences={handleChangeReferences}
        />
        <ButtonGroup>
          <CancelBtn variant="outlined" onClick={handleOpenResoucesBlock}>
            Cancel
          </CancelBtn>
          <CreateBtn onClick={handleCreateMessage} disabled={!references.length}>
            Share resource
          </CreateBtn>
        </ButtonGroup>
      </AppWrapper>
    </>
  );
};

export default ResourcesContent;
