import { Timestamp } from '@firebase/firestore';
import { IBillingData, IBodyProfile, IPartnerData } from '@fitmate-coach/fitmate-types';
import {
  IActionTracking,
  IMessageQuoted,
  IMessageReference,
  TSurveyData,
  ICoachAssignment,
  IFirebaseTimestamp,
  IFilesAttached,
  TReplyToMessage,
  IMessageEdited,
  IMessageFileEdited,
  IFilter,
  ISort,
  TClientSearchUser,
  TActionTracking,
  IUserThreadDataComplete,
} from 'types';

export interface IChat {
  pageType: string;
  handlePageType: (value: string) => void;
  handleUserListType?: (value: string) => void;
  currentUserId?: string;
  setCurrentUserId?: (currentUserId: string) => void;
  files: any[];
  handleChangeFiles: (filesList: any[]) => void;
  handleDeleteFile: (fileId: string) => void;
  messageEdited: IMessageEdited | null;
  messageFileEdited: IMessageFileEdited | null;
  handleMessageEdited: (value: IMessageEdited | null) => void;
  handleMessageFileEdited: (value: IMessageFileEdited | null) => void;
  userListType?: string;
  userList?: IUserThreadDataComplete[];
  newUsersLength?: number;
}

export enum EChatPageType {
  CONVERSATION = 'conversation',
  PROGRAM = 'program',
  USER_PROFILE = 'user profile',
  SURVEY = 'survey',
  SAVVY_DATA = 'savvy data',
}

export enum EChatUserListType {
  MY_CLIENTS = 'my_clients',
  NEW_CLIENTS = 'new_clients',
}

export interface IChatUser {
  userId: string;
  avatarUrl?: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive?: boolean;
  isSaved?: boolean;
  userIdSavedByCoach?: boolean;
  isMessagesUnRead?: boolean;
  messageLastFromUser: boolean;
  messageLastCreatedAt: number;
  lastSeenAt?: IFirebaseTimestamp;
  coachAssignment: ICoachAssignment | null;
  billingData?: IBillingData | null;
  lastMessage?: IChatMessageLast;
  isMain?: boolean;
  uid: string;
  timezone?: string;
  partner?: string;
  diabetes?: number;
  glp1drugs?: boolean;
  partnerData?: IPartnerData;
  appVersion?: string;
}

export interface IAiFeedback {
  output1: string | string[];
  output2: string;
}

export interface IChatMessage {
  id: string;
  body: string;
  createdAt: IFirebaseTimestamp;
  files: IFilesAttached[];
  isDeleted: boolean;
  isViewed?: boolean;
  isViewedByCoach?: boolean;
  isFaqChecked: boolean;
  userId: string;
  userIdFrom: string;
  userNameFrom: string;
  userAvatarUrlFrom?: string;
  replyToMessage?: IMessageQuoted;
  virtualUserIdFrom?: string;
  virtualUserNameFrom?: string;
  virtualUserAvatarUrlFrom?: string;
  references: IMessageReference[];
  tags?: string[];
  surveyData?: any;
  isSurveyCompleted?: boolean;
  isSurveyDeclined?: boolean;
  isSurveyAssigned?: boolean;
  isSurvey?: boolean;
  actionTracking?: IActionTracking;
  bodyProfileData?: IBodyProfile;
  customerCanSee?: boolean;
  type?: string | string[];
  aiFeedback?: IAiFeedback;
  isEngagementMessage?: boolean;
  isEowAutomationDelayed?: boolean;
}

export type IFirestoresChatMessage = {
  id?: string;
  userAvatarUrlFrom?: null | string;
  userNameFrom?: string;
  body?: null | string;
  userId: string;
  userIdFrom: string;
  createdAt: Timestamp;
  isDeleted: boolean;
  isViewed: boolean;
  surveyData?: TSurveyData;
  files?: File[];
  replyToMessage?: TReplyToMessage | null;
  virtualUserNameFrom?: string | null;
  virtualUserIdFrom?: null | string;
  virtualUserAvatarUrlFrom?: null | string;
  tags?: string[];
  references?: { type: 'resource' | 'resource_category' | 'handout'; referenceId: string }[];
  userRoleFrom?: string;
  isViewedByCoach?: boolean;
  userIdTo?: string;
  isFirstCouchMessage?: boolean;
  faqData?: { isChecked: boolean };
  actions?: { deeplinkUri: string; title: string }[];
  dateIn?: number;
  userIdFromName?: string;
  actionTracking?: TActionTracking;
};

export interface IChatMessageLast extends IChatMessage {
  userIdFromName?: string;
  surveyData?: any;
  messageId?: string;
  faqData?: any;
}

export interface IChatMessageTag {
  tag: string;
  numberOfUsage?: number;
  isAddedByCoach?: boolean;
}

export interface IChatMessagesListItemFiles {
  messageId: string;
  userIdFrom: string;
  userNameFrom: string;
  createdAtSeconds: number;
  currentUserId: string;
  handleMessageFileEdited: (value: IMessageFileEdited) => void;
  files: IFilesAttached[];
  isViewed: boolean;
  messageBy: string;
  showReply?: boolean;
  replyToMessage?: IMessageQuoted | null;
}

export interface IChatMessagesListItem {
  currentUserId: string;
  message: IChatMessage;
  // handleMessageQuoted: (value: MessageQuoted) => void;
  handleMessageEdited: (value: IMessageEdited) => void;
  handleMessageFileEdited: (value: IMessageFileEdited) => void;
  messageIdTagsCurrent: string | null;
  handleMessageIdTagsCurrent: (value: string | null) => void;
}

export interface IChatMessagesListItemReference {
  references: IMessageReference[];
  userIdFrom: string;
  userNameFrom: string;
  currentUserId: string;
  createdAtSeconds: number;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  isViewed: boolean;
  messageBy: string;
}

export interface IChatAddMessage {
  files: any[];
  handleChangeFiles: (filesList: any[]) => void;
  handleDeleteFile: (fileId: string) => void;
  messageEdited: IMessageEdited | null;
  messageFileEdited: IMessageFileEdited | null;
  handleMessageEdited: (value: IMessageEdited | null) => void;
  handleMessageFileEdited: (value: IMessageFileEdited | null) => void;
  handleOpenResoucesBlock: () => void;
  handleOpenCategoryBlock: () => void;
  handleOpenHandoutBlock: () => void;
  answerFAQChosen: string;
  handleAnswerFAQChosen: (value: string) => void;
  isInputMessageExpanded: boolean;
  handleIsInputMessageExpanded: (value: boolean) => void;
  onScrollChat: () => void;
  parentHeight?: number;
}

export interface IChatEditFileMessage {
  newFile: IFilesAttached;
  fileRef: any;
  messageFileEdited: IMessageFileEdited | null;
  handleMessageFileEdited: (value: IMessageFileEdited | null) => void;
}

export interface IChatEditMessage {
  messageEdited: IMessageEdited | null;
  handleMessageEdited: (value: IMessageEdited | null) => void;
}

export interface IChatAllClients {
  handleOpenAllClients: () => void;
  isOpenAllClients: boolean;
}

export interface IChatAllClientsList {
  usersList: TClientSearchUser[];
  usersSize: number;
  sortValue: ISort;
  setSortValue: (value: ISort) => void;
  filtersValues: IFilter[];
  setFiltersValues: (filters: IFilter[]) => void;
  isOpenAllClients: boolean;
}

export interface IChatAllClientsListHeader {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  usersPerPage: number;
  setUsersPerPage: (perPage: number) => void;
  totalPages: number;
}

export interface IChatAllClientsListHeaderManager {
  usersSize: number;
  sortValue: ISort;
  setSortValue: (value: ISort) => void;
  filtersValues: IFilter[];
  setFiltersValues: (filters: any) => void;
  isOpenAllClients: boolean;
}

export interface IChatAllClientsSearch {
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
}

export interface IChatControl {
  userListType?: string;
  searchValue?: string;
  setSearchValue?: (handleSearchValue: string) => void;
  handleUserListType?: (value: string) => void | undefined;
  handleOpenAllClients: (event: React.MouseEvent<HTMLElement>) => void;
  isOpenAllClients: boolean;
  newUsersLength?: number;
  filter?: string;
  setFilter?: (filter: string) => void;
}

export interface IChatThreadsExportContacts {
  userListType: string;
}

export interface IChatMessages {
  currentUserId: string;
  currentUserMessagesList: IChatMessage[];
  handleMessageEdited: (value: IMessageEdited) => void;
  handleMessageFileEdited: (value: IMessageFileEdited) => void;
  messageIdTagsCurrent: string | null;
  handleMessageIdTagsCurrent: (value: string | null) => void;
}

export interface IChatMessagesList {
  currentUserId: string;
  currentUserMessagesList: IChatMessage[];
  handleMessageEdited: (value: IMessageEdited) => void;
  handleMessageFileEdited: (value: IMessageFileEdited) => void;
  messageIdTagsCurrent: string | null;
  handleMessageIdTagsCurrent: (value: string | null) => void;
}

export interface IChatMessageTags {
  messageIdTagsCurrent: string | null;
  handleMessageIdTagsCurrent: (value: string | null) => void;
}

export interface IChatAllClientsHeader {
  handleOpenAllClients?: () => void;
  currentPage: number;
}

export interface IChatAllClientsListItem {
  user: TClientSearchUser;
  isSavedByCurrentCoach: boolean;
}
