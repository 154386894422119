import { Timestamp } from '@firebase/firestore';
import { IUserThreadDataComplete, IFirebaseTimestamp } from 'types';
import { IBodyProfile } from '@fitmate-coach/fitmate-types';

export type TProgramWeeks = TFirePlan[];

export type TProgramWeeksData = { weeks: TProgramWeeks };

export interface IActionProgramStyle {
  chipBackground: string;
  chipBorder: string;
  containerBackground: string;
  color: string;
}

export interface IActionProgramItem {
  [status: string]: {
    title: string;
    chipBackground: string;
    chipBorder: string;
    containerBackground: string;
    color: string;
  };
}

export interface IActionsProgramOptionsListItem {
  value: EActionsProgramOptions;
  label: string;
  icon: string;
}

export interface IActionTracking {
  actionId: string;
  createdAt: IFirebaseTimestamp;
  details: string;
  files?: any[];
  currentDay: number;
  daysPerWeek: number;
  assignmentTime: IFirebaseTimestamp;
  goal: string;
  canShare?: boolean;
  documentId?: string; // only used on message track progress
}

export interface IProgramPlanActionStatus {
  status?: 'published' | 'draft';
}

export interface IProgramAction {
  id?: string;
  actionId?: string | null;
  createdAt: number | null;
  updatedAt: number | null;
  planId?: string;
  planAreaId?: string;
  assignmentTime?: IFirebaseTimestamp | null;
  lastTracking?: IActionTracking;
  docId?: string | null;
  habitType?: string;
  categoryId?: string;
  draft?: IProgramActionDraftPublished;
  published?: IProgramActionDraftPublished;

  isReset?: boolean; // only available in form not model

  // We keep this for legacy apps
  goal?: string | undefined;
  title?: string;
  description?: string | undefined;
  days?: number[];
  daysPerWeek?: number;
  firstDay?: number | null;
  isNew: boolean;
  isDeleted: boolean;
  status: EActionsProgramStatus;
  subStatus?: string | null;
  strategy?: string;
  // End legacy format
}

export interface IProgramActionDraftPublished {
  goal: string;
  strategy?: string;
  description: string;
  status: EActionsProgramStatus;
  isNew: boolean;
  isDeleted: boolean;
  days: number[];
  daysPerWeek: number;
  firstDay?: number | null;
}

export type ProgramActionForm = IProgramAction & { isReset?: boolean };
export interface IAction {
  id: string;
  title: string;
  description: string;
  category: string; // delete after migration
  subcategoryId: string; // delete after migration
  handoutId?: string;
  handoutTitle?: string;
  isDeleted?: boolean;
  isActive?: boolean;
  createdAt?: number;
  categoryId: string; // new structure. after migration make as required
  habitType: string; // new structure. after migration make as required
  sortingIndex: number; // new structure. after migration make as required
}

export interface IProgramActionsListItem {
  action: IAction;
  programAction: IProgramAction | null;
}

// delete after migration
export interface IActionCategory {
  id: string;
  name: string;
}

export interface IActionCategoryNew {
  id: string;
  name: string;
  categoryId: string | null;
  habitTypes: string[];
  sortingIndex: number;
}

export interface IActionCategoryNewType {
  core: { name: string; sortingIndex: number };
  additional: { name: string; sortingIndex: number };
  obstacle: { name: string; sortingIndex: number };
  [key: string]: { name: string; sortingIndex: number };
}

export interface IActionSubCategory {
  id: string;
  category: string;
  name: string;
}

export enum EActionsProgramStatus {
  CURRENT = 'current',
  PAST = 'past',
  COMPLETE = 'completed',
  EXISTING = 'existing',
  NEW = 'new',
}

export enum EActionProgramSubStatus {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface IWeekDayOption {
  value: number;
  short: string;
  label: string;
}

export enum EActionsProgramOptions {
  ALL = 'all',
  CURRENT = 'current',
  NEW = 'new',
  EXISTING = 'existing',
  PAST = 'past',
  COMPLETE = 'completed',
}

export enum EActionsProgramKinds {
  ALL = 'all',
  SELECTED = 'selected',
}

export type TFocusArea = {
  id: string;
  title: string;
  notes?: string | null;
  disabled?: boolean;
  handoutId?: string;
  category: 'priority' | 'obstacle' | 'existing';
};

export type TFirePlanId = TFirePlan & { id: string };

export type TFirePlan = {
  week: number;
  endDate: Timestamp;
  startDate: Timestamp;
  prompt?: string;
  draft: { plan: TFocusArea[]; updatedAt?: Timestamp };
  published?: { plan: TFocusArea[]; updatedAt?: Timestamp };
  updatedAt?: Timestamp;
  lastPublishedDate?: Timestamp;
};

export type TFirestoresActionTracking = {
  createdAt: Timestamp;
  assignmentTime: Timestamp;
  goal: string;
  currentDay: number;
  files: any[];
  actionId: string;
  details?: string;
  documentId: string;
  daysPerWeek: number;
};

export interface IActionListItem {
  action: IAction;
  chooseEditAction: (actionId: string) => void;
}

export interface ICircularProgressWithLabel {
  progressValue: number;
  finishValue: number;
  color: string;
}

export interface IProgramActionsList {
  userId: string;
  actionsProgramOption: string;
  actionsProgramKind: string;
}

export interface IProgramActionModal {
  isOpenActionModal: boolean;
  closeActionModal: () => void;
  actionIdCurrent: string | null;
  action: IAction | null;
  programActions: IProgramAction[];
}
export interface IProgramFooter {
  currentUser?: IUserThreadDataComplete | null;
  handlePageType: (value: string) => void;
}

export interface IProgramContainer {
  isToggleApiToken: boolean;
  handlePageType: (value: string) => void;
  currentUser?: IUserThreadDataComplete | null;
}

export interface IProgramActionsListItemHandle {
  userId?: string;
  action: IAction; // Template Action
  programAction: IProgramAction;
  handleActionIdCurrent: (value: string) => void;
}

export interface IProgramFilterMenu {
  actionsProgramOption: string;
  handleActionsProgramOption: (value: string) => void;
}

export interface IFocusAreaStateInterface {
  loading: boolean;
  showAddModal: boolean;
  focusAreas?: any[];
  selectedFocusArea?: any;
}

export interface IProgramFilter {
  actionsProgramKind: string;
  actionsProgramOption: string;
  handleActionsProgramKind: (value: string) => void;
  handleActionsProgramOption: (value: string) => void;
}

export interface IChatProgramProfileEditGoalModal {
  bodyProfileGoal: IBodyProfile | null;
  isOpenEditGoalModal: boolean;
}

export interface IChatProgramProfileEditLatestModal {
  isOpenEditLatestModal: boolean;
}

export interface IWeekProgram {
  weeks?: any;
  selectedPlan?: string;
  currentPlanStatus?: string;
  loading: boolean;
  isNutrition: boolean;
  category?: any;
  currentWeekPlan?: any;
}

export interface IUpdateWeekProgram {
  existingPlan: any;
  weeksInput: any;
  activePlanId?: string;
  userId: string;
}
