import { createSlice } from '@reduxjs/toolkit';
import { IMessageQuoted } from 'types';

interface SendMessageState extends IMessageQuoted {
  forceFeedbackTool: boolean;
  loading: boolean;
}

const initialState: SendMessageState = {
  actionTracking: undefined,
  forceFeedbackTool: false,
  loading: false,
};

export const sendMessageSlice = createSlice({
  name: 'sendMessageSlice',
  initialState,
  reducers: {
    enableFeedbackTool: (state) => {
      state.forceFeedbackTool = true;
    },
    disableFeedbackTool: (state) => {
      state.forceFeedbackTool = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { enableFeedbackTool, disableFeedbackTool } = sendMessageSlice.actions;

export default sendMessageSlice.reducer;
