import React, { useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import {
  Restaurant as RestaurantIcon,
  Folder as FolderIcon,
  AttachFile as AttachFileIcon,
  Book as BookIcon,
  RateReview as RateReviewIcon,
} from '@mui/icons-material';
import { Alert, Switch } from '@mui/material';
import { EditorState, ContentState } from 'draft-js';
import { maxFileAttachSize, CHAT_FILES_DIR } from 'config';
import CircularProgressWithPercents from 'common/core/CircularProgressWithPercents';
import Menu from 'common/core/Menu';
import { generateBitlyLink } from 'api/bitly';
import { SimilarityService } from 'api/similarityService';
import { generateTrustPilotInvitationLink } from 'api/trustpilot';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFilesUpload } from 'hooks/useFilesUpload';
import { useChatCreateMessage } from 'hooks/chat/useChatCreateMessage';
import { useChatMessageEdit } from 'hooks/chat/useChatMessageEdit';
import { useChatMessageFileEdit } from 'hooks/chat/useChatMessageFileEdit';
import { clearQuotedMessage } from 'store/chat/quotedMessageSlice';
import { resetMessages, toggleShare } from 'store/automation/aiSlice';
import { analyzeExcludedCharactersMessage, resetAnalysis } from 'store/chat/messageAnalyzeSlice';
import { resetFeedback } from 'store/customers/progressFeedbackSlice';
import {
  convertFromStateToString,
  convertFromStringToState,
  getResetEditorState,
} from 'utils/tools/reactDraftWysiwyg';
import { sortAnswerByDate } from 'utils/format/sort';
import {
  ChipFile,
  Container,
  ExpandIcon,
  FilesBlock,
  FilesWrapperBlock,
  GreenSwitch,
  InputBlock,
  MessageIcon,
  MessageSendIcon,
  PlusIcon,
  SendBlock,
  SeparateBlock,
  SeparateNoteBlock,
  SeparateNoteText,
  SubMenu,
  SubMenuIcon,
  TextareaBlock,
  Wrapper,
} from './styles';
import { IChatAddMessage, ISimilarity } from 'types';
import ExpandOnIcon from 'assets/icons/expand_on.svg';
import ExpandOffIcon from 'assets/icons/expand_off.svg';
import InputEditor from '../InputEditor';
import SimilarityModal from '../SimilarityModal';
import ChatEditFileMessage from '../ChatEditFileMessage';
import ChatAddMessageSelectCoach from '../ChatAddMessageSelectCoach';
import { excludeRegEx, httpRegex } from 'utils/format/chat';
import { toast } from 'sonner';
import {
  handleCopyPaymentLinkWithoutTrial,
  handleCopyPaymentLinkWithTrial,
} from 'utils/tools/copyToClipboard';
import { disableFeedbackTool } from 'store/chat/sendMessageSlice';
import { resetWeeks } from 'store/program/weekProgramSlice';
import classnames from 'classnames';

const ChatAddMessageForm = ({
  files,
  handleChangeFiles,
  handleDeleteFile,
  messageEdited,
  messageFileEdited,
  handleMessageEdited,
  handleMessageFileEdited,
  handleOpenResoucesBlock,
  handleOpenCategoryBlock,
  handleOpenHandoutBlock,
  answerFAQChosen,
  handleAnswerFAQChosen,
  isInputMessageExpanded,
  handleIsInputMessageExpanded,
  onScrollChat,
  parentHeight,
}: IChatAddMessage) => {
  // In StrictMode react-draft-wysiwyg made error in console Warning: Can't call setState
  // it can be fixed in the next versions of package
  // https://github.com/jpuri/react-draft-wysiwyg/issues/951
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.moveFocusToEnd(EditorState.createEmpty()),
  );
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { messages, canShare, disableShare, shouldAddShareMessage } = useAppSelector(
    (state) => state.automationAi,
  );
  const [message, setMessage] = useState<string>('');
  const [isMessageUploading, setIsMessageUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSimilarityModalOpen, setIsSimilarityModalOpen] = useState<boolean>(false);
  const [similarity, setSimilarity] = useState<ISimilarity | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { uploadFiles, filesList, emptyFilesList, progress } = useFilesUpload();
  const fileRef = useRef<any>(null);
  const createMessage = useChatCreateMessage();
  const editMessage = useChatMessageEdit();
  const editFileMessage = useChatMessageFileEdit();

  useEffect(() => {
    if (answerFAQChosen) {
      const messageNew = answerFAQChosen;
      handleAnswerFAQChosen('');
      setMessage(messageNew);
    }
  }, [customer, answerFAQChosen]);

  useEffect(() => {
    setMessage(messageEdited?.message.body ?? '');
  }, [messageEdited]);

  useEffect(() => {
    const handleCreateMessage = async () => {
      createMessage(message, filesList, null, canShare ?? false, shouldAddShareMessage).finally(
        () => {
          dispatch(disableFeedbackTool());
          dispatch(resetWeeks());
          dispatch(clearQuotedMessage());
          dispatch(resetMessages());
          dispatch(resetAnalysis());
          dispatch(resetFeedback());
          handleMessageEdited(null);
          setIsMessageUploading(false);
        },
      );
    };

    if (isMessageUploading && files.length === filesList.length) {
      handleCreateMessage().then();
    }

    if (!isMessageUploading && (message || files.length)) {
      emptyFilesList();
      setMessage('');
      setEditorState(getResetEditorState(editorState));
      handleChangeFiles([]);
      handleIsInputMessageExpanded(true);
    }
  }, [isMessageUploading, filesList]);

  useEffect(() => {
    setMessage('');
    if (messages && messages.length > 0) {
      const lastMessageContent = messages[messages.length - 1].content || '';
      setMessage(lastMessageContent);

      // Update editorState with the new message content
      const contentState = ContentState.createFromText(lastMessageContent);
      const newEditorState = convertFromStringToState(lastMessageContent);
      setEditorState(newEditorState);
    }
  }, [messages]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleMenuResources = () => {
    handleOpenResoucesBlock();
    setAnchorEl(null);
  };

  const handleMenuCategory = () => {
    handleOpenCategoryBlock();
    setAnchorEl(null);
  };
  const handleMenuHandout = () => {
    handleOpenHandoutBlock();
    setAnchorEl(null);
  };

  const handleMenuAttachment = () => {
    handleClickFileUploader();
    setAnchorEl(null);
  };

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  const formatMessage = (message: string) => {
    if (!message) return '';
    return message.replace('\\[', '[').replace(/&#x\d{1,};/, '');
  };

  const generateTrustPilotLink = async () => {
    if (customer?.email && customer?.lastName) {
      const trustPilotUrl = await generateTrustPilotInvitationLink(
        customer.email,
        customer.lastName,
      );
      const bitlyUrl = await generateBitlyLink(trustPilotUrl);
      const messageTemplate = `I hope you've enjoyed the last [x] weeks on the program and 
        I wondered whether you'd be happy to write a review on Trustpilot?
        We’ve found these really help others decide whether the program is right for them.
        Here’s the link if you’re happy to do this: ${bitlyUrl.link}`;

      setMessage(messageTemplate);
      setAnchorEl(null);
    }
  };

  const handleTruspilotGenerateMessage = async () => {
    if (customer?.email && customer?.lastName) {
      toast.promise(generateTrustPilotLink, {
        loading: 'Generating Trustpilot message for customer',
        success: 'Trustpilot message generated',
        error: 'Error generating Trustpilot message',
      });
    }
  };

  const chooseFileInUploader = async (e: any) => {
    const filesNew: any[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const fileNew = e.target.files[i];
      if (fileNew.size <= maxFileAttachSize) {
        fileNew['id'] = v4();
        filesNew.push(fileNew);
      }
    }

    handleChangeFiles([...files, ...filesNew]);
    fileRef.current.value = '';
    setMessage(message + ' ');
  };

  useEffect(() => {
    if (messageFileEdited && filesList[0]) {
      editFileMessage(messageFileEdited, filesList).then(() => {
        handleMessageFileEdited(null);
      });
    }
  }, [messageFileEdited, filesList]);

  const handleCheckMessageAndSubmit = async () => {
    if (message.replace(httpRegex, ' ').match(excludeRegEx) === null) {
      dispatch(resetAnalysis());
      await handleSubmit();
    } else {
      dispatch(analyzeExcludedCharactersMessage(message));
    }
  };

  const handleSubmit = async () => {
    if (loading) return; //Quick way to turn off handleSubmit
    try {
      setLoading(true);
      const localMessage = convertFromStateToString(editorState);

      //TODO: (Vlad) - Remove 'dublicateMessageTest' after test
      if (!localMessage || !coachData?.dublicateMessageTest) {
        return onSendMessage();
      }

      if (customer?.id) {
        const similarity = await SimilarityService.searchSimilarity(localMessage, customer.id);
        if (similarity.documents?.length === 0 || similarity.answers?.[0]?.meta?.inWhiteList) {
          return onSendMessage();
        }
        const sortedList = similarity.documents ? similarity.documents.sort(sortAnswerByDate) : [];
        setSimilarity({
          documents: sortedList,
          ...similarity,
        });
        setIsSimilarityModalOpen(true);
      }
    } catch (error: any) {
      onSendMessage();
      throw new Error(`Duplicate message error: ${error?.message || 'other errors'}`);
    } finally {
      setLoading(false);
    }
  };

  const onSendMessage = () => {
    onCloseSimilarityModal();

    const localMessage = convertFromStateToString(editorState);

    if (messageEdited) {
      editMessage(messageEdited.message.id, message).then(() => {
        handleMessageEdited(null);
        setMessage('');
        setEditorState(getResetEditorState(editorState));
      });
    } else if (messageFileEdited) {
      uploadFiles(files, CHAT_FILES_DIR);
    } else if ((localMessage || files.length) && !isMessageUploading) {
      uploadFiles(files, CHAT_FILES_DIR).then(() => {
        setIsMessageUploading(true);
      });
    }
  };

  const onCloseSimilarityModal = () => {
    setIsSimilarityModalOpen(false);
    setSimilarity(null);
    setEditorState(EditorState.moveFocusToEnd(editorState));
  };

  // accept='image/*, audio/*, video/*,.pdf'

  const onExpandedChat = () => {
    handleIsInputMessageExpanded(!isInputMessageExpanded);
    if (!isInputMessageExpanded) {
      const timer = setTimeout(() => {
        onScrollChat();
      }, 300);
      return () => clearTimeout(timer);
    }
  };

  const toggleShareBadge = () => {
    dispatch(toggleShare());
  };

  if (!customer?.appVersion) {
    return (
      <Alert severity="error">
        You cannot send messages until customer have installed the app.
      </Alert>
    );
  }

  return (
    <>
      <Wrapper>
        <Container>
          {messages && messages.length > 0 && !disableShare && (
            <div className="w-full bg-[#F2F4F7] border-b flex items-center text-[14px] p-2">
              <GreenSwitch
                className={classnames('text-[#52CDB1]')}
                checked={canShare}
                onChange={toggleShareBadge}
              />{' '}
              {canShare ? (
                <p className="text-[#52CDB1] font-semibold">Healthy meal badge awarded</p>
              ) : (
                <p className="text-[#0F0F2199]">No healthy meal badge</p>
              )}
              <p className="ml-4">
                If the system got it wrong, you can change the badge status and edit the feedback.
              </p>
            </div>
          )}

          {files.length !== 0 && !messageFileEdited && (
            <FilesWrapperBlock>
              <FilesBlock>
                {files.map((file: any) => (
                  <ChipFile
                    key={file.id}
                    label={file.name}
                    onDelete={() => handleDeleteFile(file.id)}
                    size="small"
                  />
                ))}
              </FilesBlock>
            </FilesWrapperBlock>
          )}

          {messageFileEdited && (
            <ChatEditFileMessage
              newFile={files[0]}
              fileRef={fileRef}
              messageFileEdited={messageFileEdited}
              handleMessageFileEdited={handleMessageFileEdited}
            />
          )}
          <InputBlock>
            <SeparateBlock>
              {messages && messages.length > 0 && (
                <div className="text-[10px] bg-yellow-200 rounded text-center p-1 mt-2">
                  Recommended copilot response
                </div>
              )}
              <div>
                <SeparateNoteBlock>
                  <SeparateNoteText>Send as</SeparateNoteText>
                </SeparateNoteBlock>
                {customer?.id && <ChatAddMessageSelectCoach />}
              </div>
            </SeparateBlock>
            <TextareaBlock>
              {customer?.id && (
                <InputEditor
                  message={formatMessage(message)}
                  handleMessage={setMessage}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  isInputMessageExpanded={isInputMessageExpanded}
                  currentUserId={customer.id}
                  parentHeight={parentHeight}
                />
              )}
              <ExpandIcon
                src={isInputMessageExpanded ? ExpandOffIcon : ExpandOnIcon}
                onClick={onExpandedChat}
                onKeyDown={onExpandedChat}
                alt=""
              />
              <div onClick={toggleMenu} onKeyDown={toggleMenu}>
                <PlusIcon />
              </div>
            </TextareaBlock>
            <SendBlock>
              {(isMessageUploading || loading) &&
                (files.length ? (
                  <CircularProgressWithPercents value={progress} />
                ) : (
                  <MessageIcon size={25} />
                ))}
              {!isMessageUploading && !loading && (
                <MessageSendIcon
                  isActive={!!(message || files.length)}
                  onClick={handleCheckMessageAndSubmit}
                />
              )}
            </SendBlock>
          </InputBlock>
        </Container>
      </Wrapper>
      {messageFileEdited ? (
        <input
          type="file"
          id="file"
          ref={fileRef}
          onChange={chooseFileInUploader}
          accept="image/*, audio/*, video/*"
          style={{ display: 'none' }}
        />
      ) : (
        <input
          type="file"
          id="file"
          ref={fileRef}
          onChange={chooseFileInUploader}
          multiple
          accept="image/*, audio/*, video/*"
          style={{ display: 'none' }}
        />
      )}
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={() => handleCopyPaymentLinkWithTrial(customer)}>
          Payment link (with trial)
        </SubMenu>
        <SubMenu onClick={() => handleCopyPaymentLinkWithoutTrial(customer)}>
          Payment link (no trial)
        </SubMenu>
        <SubMenu onClick={handleTruspilotGenerateMessage}>
          Trustpilot invitation
          <SubMenuIcon as={RateReviewIcon} />
        </SubMenu>
        <SubMenu onClick={handleMenuResources}>
          Resources
          <SubMenuIcon as={RestaurantIcon} />
        </SubMenu>
        <SubMenu onClick={handleMenuHandout}>
          Handout
          <SubMenuIcon as={BookIcon} />
        </SubMenu>
        <SubMenu onClick={handleMenuCategory}>
          Category
          <SubMenuIcon as={FolderIcon} />
        </SubMenu>
        <SubMenu onClick={handleMenuAttachment}>
          Attachment
          <SubMenuIcon as={AttachFileIcon} />
        </SubMenu>
      </Menu>
      <SimilarityModal
        isOpen={isSimilarityModalOpen}
        onHandleClose={onCloseSimilarityModal}
        onSendMessage={onSendMessage}
        currentMessage={similarity?.query}
        documents={similarity?.documents}
      />
    </>
  );
};

export default ChatAddMessageForm;
